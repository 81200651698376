.Login {
    display: inline-block;
    margin: 0;
    padding: 6.5rem 2.5rem 2.5rem 2.5rem;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    min-height: calc(100vh - 3.6rem);
    .container {
      margin-top: 0;
      margin-bottom: 0;
      padding: 2.5rem;
      background-color: #fff;
    }
  }
