.popup-overlay {
    &:before {
        content: '';
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        background-color: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(0.5rem);
        z-index: 10;
    }
    .modal {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        width: 80vw;
        height: auto;
        max-height: 90vh;
        background-color: transparent;
        z-index: 100;
        border: 0.1rem solid rgba(0, 0, 0, 0.5);
        border-radius: 1.5rem;
        box-shadow: 1rem 1rem 0.5rem 0.1rem rgba(0, 0, 0, 0.2);
        .Loading {
            width: auto;
            height: auto;
        }
        .TodoInfos {
            max-height: 90vh;
            overflow-x: hidden;
            overflow-y: auto;
            .TodoInfosContent {
                margin-right: 1rem;
                margin-left: 1rem;
                width: calc(100% - 2rem);
                margin-bottom: 10rem;
            }
            .TodoInfosTitle,
            .TodoInfosDesc {
                margin: 0 0 3rem 0; padding: 0;
                span {
                    display: inline-block; margin: 0; padding: 0; width: 100%;
                    &.label {
                        border-bottom: .1rem solid rgba(0, 0, 0, 0.2); font-style: italic;
                    }
                }
            }
            .MuiSwitch-sizeSmall .MuiSwitch-switchBase.Mui-checked {
                color: #00ff64;
            }
            .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
                background-color: #3cff8a;
            }
        }
        & > {
            .content {
                width: 100%;
                min-height: 12rem;
                margin: 0;
                padding: 3rem 2rem;
                background-color: white;
            }
            .close {
                cursor: pointer;
                position: absolute;
                right: 1rem;
                top: 1rem;
                display: block;
                padding: 0.1rem 0.5rem 0.2rem 0.5rem;
                border-radius: 50%;
                border: 1px solid #cfcece;
                background: #ffffff;
                line-height: 2rem;
                font-size: 2.4rem;
                text-decoration: none;
            }
        }
    }
}
