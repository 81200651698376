.Todos {
    display: inline-block;
    margin: 0;
    padding: 6.5rem 2.5rem 2.5rem 2.5rem;
    text-align: center;
    // background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    min-height: calc(100vh - 3.6rem);
    .text-left {
        text-align: left;
    }
    .text-center {
        text-align: center;
    }
    .text-right {
        text-align: right;
    }
    > .container {
        margin-top: 0;
        margin-bottom: 0;
        padding: 2.5rem;
        // background-color: rgba( 255, 255, 255, .8 );
    }

    .todo-add {
        padding-right: 1.75rem; padding-left: 1.75rem;
        &::before {
            content: "";
            display: block; width: 1rem;height: 1rem;
            background-image: url('./cross.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: content;
            transition: background-image .2s linear;
        }
        &:hover, &:focus {
            background-color: rgba( #0F0FFF, .1 );
            outline: 0;
        }
        &:active {
            background-color: rgba( #0F0FFF, 1 );
            color: #FFF;
            &::before {
                background-image: url('./cross-hover.svg');
            }
        }
    }
}

.TodoAdd {
    .container {
        background-color: white;
        border-radius: 6rem;
    }
}
