////
/// Reset styles
/// Ease cross browser styling
///
/// @group Generic
/// @author Inouit
////

@import "../node_modules/animate.css/animate";

/// Reset font-size for easier use : 1.6em = 16px
html {
  font-size: 62.5%;
  line-height: 1.3em;
}
body {
  position: relative;
  margin: 0 auto;
  padding: 0;
  overflow-x: hidden;
  border: 0;
  font-size: 1em;
  text-align: center;
  min-height: 100vh;
  max-width: 100vw;
  background-color: #E79A9A;
  // background-image: url("./background.png");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;
}

/// Reset nested font-size
blockquote p,
td p,
th p,
li li,
li p,
li h1,
li h2,
li h3,
li h4,
li h5,
li h6,
button {
  font-size: 100%;
}

// Links & buttons
button {
  display: inline;
  margin: 0;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
}
a img {
  border: none;
}

// Images
img {
  max-width: 100%;
  height: auto;
}

// Separator
hr {
  border: none;
  height: 1px;
  background-color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

li {
  margin: 5px 0;
}
p {
  margin: 15px 0;
}
p,
li,
td,
th,
textarea,
caption,
pre,
blockquote,
label,
input,
select {
  font-size: 1.5em;
  line-height: 1.55em;
  color: #000;
}
td p,
td .bodytext {
  margin: 0;
}
