.btn.btn-primary {
	background-color: transparent;
	border: .6rem solid #0F0FFF;
	border-radius: 6rem; 
	padding: 1rem 6rem;
	margin-left: 1.8rem;
	color: #0F0FFF;
    transition: background 1.75s linear;
	font-size: 1.6rem;
	font-weight: 700;
}