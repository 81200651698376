.input {
    position: relative;
    display: flex;
    margin-bottom: 2.5rem;
    input {
        position: relative;
        border: none;
        z-index: 2;
        background-color: transparent;
        border-radius: 6rem;
        border: .2rem solid rgba( #000000, .35 );
        padding: 2rem 2.5rem;
        width: 100%;
        font-size: 1.6rem;
        &:focus {
            border-color: #0F0FFF;
            background-color: rgba( #0F0FFF, .1 );
            & + label {
                z-index: 3;
                top: 25%;
                font-size: 1rem;
            }
        }
        &:valid {
            border-color: #0F0FFF;
            background-color: transparent;
            & + label {
                z-index: 3;
                top: 25%;
                font-size: 1rem;
            }

        }
    }
    label {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 2.7rem;
        transform: translateY( -50% );
        background-color: transparent;
        transition: top .2s linear, background-color .2s linear;
        font-size: 1.6rem;
    }
}