.TodoInfos {
    position: relative;
    margin: 0 0 1rem 0;
    p {
        display: inline-block;
        margin: 0;
        padding: 0;
        width: 100%;
        + p {
            margin-top: 2rem;
        }
    }
    > span {
        display: inline-block;
        margin: 0;
        padding: 0.6rem 1rem;
        width: calc((100% - 5rem) / 5);
    }
}

.TodoInfosContent {
    position: relative;
}


.circle {
    position: relative; display: inline-block; 
    margin: 1rem; width: 6rem; height: 6rem; border: .1rem solid rgba( 0, 0, 0, .5 ); border-radius: 100%;
    background-color: rgba( 255, 255, 255, 1 );
    &:before {
        content: '';
        position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%);
        display: inline-block; border-radius: 100%;
    }
    &:before {
        width: 3rem; height: 3rem;
        background-color: #C7C7DB;
    }
    &.yellow1 {
        &:before {
            background-color: #FFED47;
        }
    }
    &.purple1 {
        &:before {
            background-color: #9363F9;
        }
    }
    &.green1 {
        &:before {
            background-color: #63F9BA;
        }
    }
    &.red1 {
        &:before {
            background-color: #D64545;
        }
    }
    &.blue1 {
        &:before {
            background-color: #27A2D7;
        }
    }
    &.orange1 {
        &:before {
            background-color: #EEB359;
        }
    }

    &.yellow2 {
        &:before {
            background-color: #DBDE3B;
        }
    }
    &.purple2 {
        &:before {
            background-color: #CB47CE;
        }
    }
    &.green2 {
        &:before {
            background-color: #56C95B;
        }
    }
    &.red2 {
        &:before {
            background-color: #F47D7D;
        }
    }
}
.circleFull {
    // background-color: rgba( 0, 0, 0, .9 );
    // &:before {
    //     display: none;
    // }
}
.circleToDay {
    position: relative;
    background-color: rgba( 255, 255, 255, 1 );
    &:before,
    &:after {
        content: '';
        position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%);
        display: inline-block; border-radius: 100%;
    }
    &:before {
        width: 3rem; height: 3rem;
        background-color: #C7C7DB;
    }
    &:after {
        width: 2rem; height: 2rem;
        background-color: transparent; background-image: url('./dot-current.svg');
    }
}
.circleEnable {
    background: radial-gradient(closest-side, rgba(255,0,0,1) 0%, rgba(255,165,0,1) 16.66%, rgba(255,255,0,1) 33.32%, rgba(0,128,0,1) 49.98%, rgba(0,0,255,1) 66.64%, rgba(75,0,130,1) 83.3%, rgba(238,130,238,1) 100%);
    transition: background 1.75s linear;
    animation: radial-gradient 4s ease infinite;
    background-position: center;
    cursor: pointer;
}
.circleChecked {
    transition: background .5s linear;
    background-color: rgb(0, 255, 100);
    &:after {
        transform: rotate(-65deg);
        animation: checked-smiley 3s linear infinite;
        content: '';
        position: absolute; top: -1.125rem; left: -1.125rem;
        display: inline-block; border-radius: 100%;
        width: 8rem; height: 8rem;
        background-color: transparent; background-image: url('./checked.svg');
    }
    &.yellow1 {
        background-color: #FFED47;
    }
    &.purple1 {
        background-color: #9363F9;
    }
    &.green1 {
        background-color: #63F9BA;
    }
    &.red1 {
        background-color: #D64545;
    }
    &.blue1 {
        background-color: #27A2D7;
    }
    &.orange1 {
        background-color: #EEB359;
    }

    &.yellow2 {
        background-color: #DBDE3B;
    }
    &.purple2 {
        background-color: #CB47CE;
    }
    &.green2 {
        background-color: #56C95B;
    }
    &.red2 {
        background-color: #F47D7D;
    }
}
.circleFull.circleEnable {
    transition: background .5s linear;
    background: rgb(255, 50, 0);
    &:before {
        display: none;
    }
}
.circleHalf:before {
    content: '';
    position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%);
    display: inline-block; width: 5px; height: 5px; background-color: black; border-radius: 100%;
}

@keyframes checked-smiley {
	0% {
		transform: rotate(-65deg);
	}
	// 25% {
	// 	transform: rotate(90deg);
	// }
	// 50% {
	// 	transform: rotate(180deg);
	// }
	// 75% {
	// 	transform: rotate(270deg);
	// }
	100% {
		transform: rotate(295deg);
	}
}

@keyframes radial-gradient {
	0% {
		background-size: 100% 100%;
	}
	25% {
		background-size: 150% 150%;
	}
	50% {
		background-size: 200% 200%;
	}
	75% {
		background-size: 150% 150%;
	}
	100% {
		background-size: 100% 100%;
	}
}